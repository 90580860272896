var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                [
                  _c("CCol", { staticClass: "title", attrs: { md: "9" } }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "pages.customer.customerOrderDetail.identifier",
                              { orderId: _vm.shortOrderId }
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "pages.customer.customerOrderDetail.status",
                              { status: _vm.translatedStatus }
                            )
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.statusInfo) + " ")
                    ])
                  ]),
                  _c(
                    "CCol",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "message-to-host",
                          attrs: { color: "secondary" },
                          on: { click: _vm.messageToHost }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "pages.owner.ownerOrderDetail.writeMessage",
                                  { guestName: _vm.order.ownerName }
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("MessageToHostModal", {
        attrs: {
          show: _vm.showMessageToHostModal,
          playroom: Object.assign({}, _vm.order, {
            ownerDisplayName: _vm.order.ownerName
          })
        },
        on: { onUpdateShow: _vm.closeMessageToHostModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }