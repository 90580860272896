var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.ready
        ? _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "12" } },
                [
                  _c("OrderDetailTitlePart", { attrs: { order: _vm.order } }),
                  _c("OrderDetailGeneralInformationPart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("OrderDetailPricePart", {
                    attrs: { order: _vm.order, showTax: false }
                  }),
                  _c("OrderDetailImportantInformationPart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("OrderDetailEventsPart", { attrs: { order: _vm.order } }),
                  _vm.order.status == "Confirmed" ||
                  _vm.order.status == "Pending"
                    ? _c("GuestOrderDetailButtons", {
                        attrs: { order: _vm.order }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }