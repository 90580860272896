var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canBeWithdrawn || _vm.canBeCancelled
    ? _c(
        "CCard",
        [
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { staticClass: "content-right", attrs: { md: "12" } },
                    [
                      _vm.canBeWithdrawn
                        ? _c("CButton", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value:
                                  "components.guestOrderDetailButtons.withdraw",
                                expression:
                                  "'components.guestOrderDetailButtons.withdraw'"
                              }
                            ],
                            staticClass: "mr10",
                            attrs: { color: "danger" },
                            on: { click: _vm.handleWithdraw }
                          })
                        : _vm._e(),
                      _vm.canBeCancelled
                        ? _c("CButton", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value:
                                  "components.guestOrderDetailButtons.cancel",
                                expression:
                                  "'components.guestOrderDetailButtons.cancel'"
                              }
                            ],
                            attrs: { color: "primary" },
                            on: { click: _vm.handleCancel }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("BookingButtonsModal", {
            attrs: {
              show: _vm.showConfirmationModal,
              title: _vm.title,
              label: _vm.label,
              btnLabel: _vm.btnLabel,
              note: _vm.cancellationNote
            },
            on: { onUpdateShow: _vm.onUpdateShow, onClose: _vm.onCloseModal }
          }),
          _c("Notification", {
            attrs: { text: _vm.popupMessage, show: _vm.showNotification },
            on: {
              onClose: function() {
                return _vm.handleCloseNotification()
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }